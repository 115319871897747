<template>
<div class="tableNav">
    <el-menu class="el-menu" mode="horizontal" default-active=0
        background-color="#FFF" text-color="#949DA1" active-text-color="#354053">
        <el-menu-item :key="key" v-for="(val,key) in menuData" :index="key+''" @click='Switch(val)'>{{val.label}}</el-menu-item>
    </el-menu>
</div>
</template>

<script>
export default {
  name: 'TableNav',
  props: ['menuData'],
  methods: {
    Switch: function (data) {
      this.$emit('tabSwitch', data)
    }
  }
}
</script>

<style scoped lang="scss">
.tableNav{
    background: #FFFFFF;
    z-index: 2;
    position: relative;
    .el-menu-item{
      height: 64px;
      line-height: 64px;
      font-size: 18px;
    }
    .button{
        display: flex;
        margin: 0;
        li{
            display: block;
            padding: 0 10px;
            padding-top:5px;
            padding-bottom:5px;
            border-bottom: 2px solid #fff;
            cursor: pointer;
            font-size: 14px;
            .buttonList{
                display: none;
                position: absolute;
                background: #fff;
                padding: 5px 10px;
                border: 1px solid #ccc;
                .list{
                    margin: 5px 0;
                    &:hover{
                        color: rgb(196, 51, 51);
                    }
                }
            }
            &:hover{
                border-bottom: 2px #b01f24 solid;
                .buttonList{
                    display: block;
                }
            }
        }
    }
}
</style>
